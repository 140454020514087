import React, { useState } from "react";
import { Box } from "@mui/material";

function Appendix() {
  const [isObjectiveCollapsed, setIsObjectiveCollapsed] = useState(true);

  const toggleObjectiveCollapse = () => {
    setIsObjectiveCollapsed(!isObjectiveCollapsed);
  };

  const getMinimumStandardLogs = () => {
    return (
      <div className="collapsible-log-content">
        Version 4.0
        <ul>
          <li>Published: July, 2024</li>
          <li>
            CORRECTION: July, 2024
            <ul>
              <li>
                Safeguard 6.3 - Removed “checkmark” for “low-risk”; changed
                “checkmark” to “plus” for “moderate-risk” and “high-risk”; added
                “plus” for “workforce”
              </li>
              <li>
                Safeguard 6.4 - Removed “checkmark” for “low-risk”,
                “moderate-risk” and “high-risk”; added “checkmark” for
                “workforce”
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Box
      id="logs"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div className="collapsible-log">
        <div className="collapsible-log-header" onClick={toggleObjectiveCollapse}>
          {isObjectiveCollapsed ? <span>▶</span> : <span>▼</span>}
          Revisions and Change Log
        </div>
        {!isObjectiveCollapsed && getMinimumStandardLogs()}
      </div>
    </Box>
  );
}

export default Appendix;
