import React from 'react';
import './App.css'

function Header() {
  return (
    <header>
      <div className="vt-header">
        <a href="https://vt.edu" target="_blank" rel="noreferrer">
          <img
            src="https://www.assets.cms.vt.edu/images/HorizontalStacked/jpg/HorizontalStacked_RGB_white.jpg"
            alt="VT Virginia Tech"
          />
        </a>
        <div className="vt-title">Virginia Tech IT Security Standards</div>
      </div>

      <div className="vt-header-content">
        Virginia Tech is committed to protecting the privacy of its students,
        alumni, current and former employees, retirees, research participants,
        and all other internal or external customers as well as protecting other
        information important to the university's mission. This standard
        supports{" "}
        <a
          href="https://policies.vt.edu/assets/7010.pdf"
          target="_blank"
          rel="noreferrer"
        >
          university policy no. 7010 - Policy for Securing Technology Resources
          and Services
        </a>{" "}
        and establishes the university’s minimum information security control
        requirements for university data and information technology resources in
        order to uphold the information security objectives collectively
        referred to as the “CIA triad” of computer security:
        <ul>
          <li>
            <span className="firstWord">Confidentiality</span> - Preserving
            authorized restrictions on information access and disclosure,
            including means for protecting personal privacy and proprietary
            information;
          </li>
          <li>
            <span className="firstWord">Integrity</span> - Guarding against
            unauthorized information modification and ensuring information
            non-repudiation and authenticity;
          </li>
          <li>
            <span className="firstWord">Availability</span> - Ensuring timely
            and reliable access to and use of information.
          </li>
        </ul>
        This standard is intended to reflect the minimum level of care necessary
        for Virginia Tech's data. It does not relieve Virginia Tech or its
        employees, partners, consultants, or vendors of further obligations that
        may be imposed by law, regulation or contract. Virginia Tech expects all
        partners, consultants, and vendors to abide by Virginia Tech's
        information security policies. If nonpublic information is to be
        accessed or shared with these third parties, they should be
        contractually obligated to abide by Virginia Tech's information security
        policies.
      </div>
    </header>
  );
}

export default Header;