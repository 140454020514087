import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Box, List, ListItem } from "@mui/material";

function Navbar() {
  // const [isObjectiveCollapsed, setIsObjectiveCollapsed] = useState(true);

  // const toggleObjectiveCollapse = () => {
  //   setIsObjectiveCollapsed(!isObjectiveCollapsed);
  // };

  const getMinimumStandardDetails = () => {
    return (
      <>
        <div className="collapsible-content">
          This standard is based on the Center for Internet Security’s (CIS)
          “Critical Security Controls” version 8, to include the “Safeguards”
          designated by CIS as belonging to Implementation Groups (IG) 1 and 2.
          The Minimum Security Standard outlines the security controlls to be
          implemented at Virginia Tech based on the sensitivity of university
          data handled in organizational business processes and the technology
          resources and/or applications used to store, process, or otherwise
          handle the data. The{" "}
          <a
            href="https://it.vt.edu/content/dam/it_vt_edu/policies/Virginia-Tech-Risk-Classifications.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Virginia Tech Risk Classification Standard
          </a>{" "}
          is first used to determine the appropriate risk classification for
          university data (“Low-Risk”, “Moderate-Risk”, or “High-Risk”), and any
          technology resources (endpoints, servers, etc.) or applications used
          to process or store the data are also classified accordingly based on
          the highest data risk classification handled by the asset(s).
          <p>
            Each section of the standard aligns to one of the 18 CIS Critical
            Security Controls v8 (CIS Controls) and defines the <em>general</em>{" "}
            scope of each of the control’s IG1 and IG2 safeguards to university
            data, technology resources (endpoints, servers), and/or applications
            by risk classification, as well as to network infrastructure devices
            and university workforce or other personnel who work with university
            data and utilize technology resources (IT users). Many safeguards
            have a broad scope that may impact several or all of these items,
            while some safeguards have a narrower scope. Further, some
            individual safeguards may define a specific scope (e.g. “4.4 -
            Implement and Manage a Firewall on <em>Servers</em>”). Finally, some
            safeguards may be entirely not applicable to a given organizational
            unit (e.g. “12.1 - Ensure <em>Network Infrastructure</em> is
            Up-to-Date” is out of scope for a unit that does not own or manage
            network infrastructure assets). It is the responsibility of the
            organizational unit, in coordination with the IT Security Office, to
            determine the scope of this standard as it applies to their
            organization’s operations.
          </p>
          The standard also defines the <em>general</em> implementation
          responsibility for each safeguard as follows:
          <ul>
            <li>
              <span className="firstWord">Local</span>: Safeguard is
              implemented/managed by the asset owner/organizational unit using
              guidance published in the ITSO implementation guides (
              <a
                href="https://security.vt.edu/procedures/"
                target="_blank"
                rel="noreferrer"
              >
                https://security.vt.edu/procedures/
              </a>
              ), or other acceptable industry guidance where appropriate or
              necessary.
            </li>
            <li>
              <span className="firstWord">Shared</span>: Safeguard is
              implemented/managed via “shared responsibility”, where the
              Division of IT provides a solution that partially satisfies
              compliance for collective benefit, but where further action is
              required by the asset owner/local org unit to achieve full
              compliance using guidance published in the ITSO implementation
              guides (
              <a
                href="https://security.vt.edu/procedures/"
                target="_blank"
                rel="noreferrer"
              >
                https://security.vt.edu/procedures/
              </a>
              ), or other acceptable industry guidance where appropriate or
              necessary.
            </li>
            <li>
              <span className="firstWord">DoIT</span>: Safeguard is
              implemented/managed entirely by the Division of IT (DoIT) for the
              collective benefit of Virginia Tech.
            </li>
          </ul>
          <p>
            Furthermore, Virginia Tech’s implementation of the CIS Controls
            represents an ongoing effort to elevate the institution’s security
            posture through continuous assessment and enhancement of IT security
            capabilities. This standard will reflect the general implementation
            maturity of each CIS safeguard based upon existing enterprise
            technology capabilities and will be updated as needed. Additional
            information regarding current implementation details and
            requirements will be published in the ITSO implementation guides (
            <a
              href="https://security.vt.edu/procedures/"
              target="_blank"
              rel="noreferrer"
            >
              https://security.vt.edu/procedures/
            </a>
            ).
          </p>
          The standard defines the current implementation maturity (as of
          current version) for each safeguard as follows:
          <List>
            <ListItem>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Acherus Grotesque Bold",
                }}
              >
                <DoneIcon /> (Checkmark)
              </Box>
              : Safeguard is fully implemented using existing university
              capabilities.
            </ListItem>
            <ListItem>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Acherus Grotesque Bold",
                }}
              >
                <AddIcon /> (Plus)
              </Box>
              : Safeguard is partially implemented using existing university
              capabilities.
            </ListItem>
            <ListItem>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Acherus Grotesque Bold",
                }}
              >
                <CircleOutlinedIcon /> (Circle)
              </Box>
              : Safeguard is currently optional; however, future maturation in
              university capabilities may support a partial or full
              implementation.
            </ListItem>
          </List>
        </div>
      </>
    );
  }

  const dataClassificationDetails = () => {
    return (
      <div className="collapsible-content">
        Virginia Tech is committed to protecting the privacy of its students,
        alumni, current and former employees, retirees, research participants,
        and all other internal or external customers as well as protecting other
        information important to the university's mission. This standard
        supports university policy no. 7010 -{" "}
        <a
          href="https://policies.vt.edu/assets/7010.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Policy for Securing Technology Resources and Services
        </a>{" "}
        and establishes the university's data and IT classification scheme for
        the purpose of determining appropriate controls (safeguards and/or
        countermeasures) that should be in place for university data and IT
        assets in order to uphold the information security objectives
        collectively referred to as the "CIA triad" of computer security:
        <ul>
          <li>
            <span className="firstWord">Confidentiality</span> - Preserving
            authorized restrictions on information access and disclosure,
            including means for protecting personal privacy and proprietary
            information;
          </li>
          <li>
            <span className="firstWord">Integrity</span> - Guarding against
            unauthorized information modification and ensuring information
            non-repudiation and authenticity;
          </li>
          <li>
            <span className="firstWord">Availability</span> - Ensuring timely and
            reliable access to and use of information.
          </li>
        </ul>
      </div>
    );
  }

  const renderDetails = () => {
    const path = window.location.pathname;
    switch(path) {
      case "/":
        return getMinimumStandardDetails();
      case "/dataClassification":
        return dataClassificationDetails();
      default:
          return null;
    }
  }

  return (
    <div>
      <div id="nav">
        <ul>
          <li>
            <a href="/#">Minimum Security Standard v4.0</a>
          </li>
        </ul>
      </div>
      <Box
        id="details"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div id="objective" className="collapsible">
          {/* <div className="collapsible-header" onClick={toggleObjectiveCollapse}>
            {isObjectiveCollapsed ? <span>▶</span> : <span>▼</span>}
            Details
          </div> */}
          {/* {!isObjectiveCollapsed && renderDetails()} */}
          {renderDetails()}
        </div>
      </Box>
    </div>
  );
}

export default Navbar;
